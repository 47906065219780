//---- Angular -----
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
//---- 3rdparty ----
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
//---- Hostware ----

import { TranslateService } from '@ngx-translate/core';
import { BrandingDto } from '../../../froexishared/src/dto/BrandingDto';
import { BrandingService } from '../../../froexishared/src/services/branding.service';
import { GenericMessageDialogComponent } from './dialogs/generic-message-dialog/generic-message-dialog.component';
import { GuestLogin } from './dto/GuestLogin';
import { GuestLoginResponse } from './dto/GuestLoginResponse';
import { ReservationInfo } from './dto/ReservationInfo';
import SessionStorage from './helpers/SessionStorage';
import { UrlData } from './interfaces/UrlData';
import { AuthService } from './services/auth.service';
import { BottomBarService } from './services/bottom-bar.service';
import { ReservationService } from './services/reservation.service';
import { SpinnerService } from './services/spinner.service';
import { UrlDataService } from './services/urlData.service';
import { GuestStayStatus } from './types/Types';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { RouterOutletService } from './services/router-outlet.service';
import { SubscriberParameterService } from '../../../froexishared/src/services/SubscriberParameter.service';
import { SubscriberParameter } from '../../../froexishared/src/dto/SubscriberParameter';
import { IpAddressService } from './services/ip-address-service';
import { __values } from 'tslib';

@AutoUnsubscribe()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'froexi_cutomerfacing';
  spinnerVisible: boolean;
  bottomBarVisible: boolean = true;
  loadBrandings$: Subscription;
  loadSubscriberParameters$ : Subscription;
s
  invited: string | undefined = undefined;
  urlData: UrlData;

  constructor(
    private router: Router,
    private reservationService: ReservationService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private bottomBarService: BottomBarService,
    private errorDialog: MatDialog,
    private translate: TranslateService,
    private brandingService: BrandingService,
    private urlDataService: UrlDataService,
    private routerOutletService : RouterOutletService,
    private subscriberParamaterService : SubscriberParameterService,
    private ipService : IpAddressService
  ) {
    SessionStorage.MrzData = undefined;
    SessionStorage.FaceImageFromCard = '';
    SessionStorage.Selfie = '';
  }

  ngOnInit(): void {
    console.log('app component')
    this.spinnerService.spinnerVisibilityChanged.subscribe(event => {
      this.spinnerVisible = event;
    })
    this.urlDataService.urlData$.subscribe((data: UrlData) => {
      /**
       * A kivett adatokat a querryParamból service-en keresztül next-eljük át ide.
       * Ami tartalmaz:
       * .guestStayUuid: string;
       * hotel3pId: string;
       * invited: string;
      */
      this.urlData = data;

     

      if (this.urlData.guestStayUuid) {
        this.spinnerService.setSpinnerVisibility(true);

        if (this.urlData.invite === "true") {
          this.invited = 'Y'
        } else {
          this.invited = 'N'
        }

        /**
         * Összeállítjuk a felhasználó adatait, hogy tokent kérjünk a szervertől.
         */
        const guestLogin: GuestLogin =
        {
          GuestStayId: this.urlData.guestStayUuid,
          Hotel3pId: this.urlData.hotel3pId
        };
        this.ipService.getIpFromIpify().subscribe( result=> {
          SessionStorage.IpAddress = result["ip"] as string;
        })
        this.authService.CreateGuestToken(guestLogin).subscribe({
          next: (loginRes) => {
            const guestLoginRestponse = loginRes as GuestLoginResponse;
            SessionStorage.GuestData = guestLoginRestponse;
            SessionStorage.RetryCountImage = 0;
            SessionStorage.RetryCountMrz = 0;
            SessionStorage.RetryCountSelfie = 0;

            /**
             * Lekérjük a foglalás adatait.
             */
            this.reservationService.GetReservationInfo(this.urlData.guestStayUuid, this.invited).subscribe({
              next: (result) => {
                this.spinnerService.setSpinnerVisibility(false);
                let res = result as ReservationInfo;
                res.Rooms.forEach(reservation=> {
                  reservation.Guests = reservation.Guests.filter( guest=>guest.Status  !== GuestStayStatus.CANCELED)
                })
                res.Rooms = res.Rooms.filter( item=> item.Guests.length > 0)
                res.Invited = this.invited;  
                SessionStorage.ReservationInfo = res;
                SessionStorage.MrzData = undefined;
                SessionStorage.FaceImageFromCard = '';
                SessionStorage.Selfie = '';
                this.loadBrandings$ = this.brandingService.LoadOnebySubscriber(SessionStorage.ReservationInfo.Subscriber, parseInt(SessionStorage.ReservationInfo.Hotelid)).subscribe(result => {
                  const br = result as BrandingDto;
                  if (br) {
                    SessionStorage.BrandingData = br;
                  }
                  if (this.hasRegisteredGuest(res)) {
                    this.router.navigateByUrl('/languageselection')
                  } else {
                    this.errorModalWithMessage('app.no_registered_guest')
                  }
                })
                this.loadSubscriberParameters$ = this.subscriberParamaterService.loadAll(SessionStorage.ReservationInfo.Subscriber, parseInt(SessionStorage.ReservationInfo.Hotelid)).subscribe(result=> {
                  const parameters = result as SubscriberParameter[];
                  SessionStorage.ChatVisibility = parameters.find(item=>item.Param_Typecode == "CHAT_VISIBILITY" ).Param_String_Value
                })
              },
              error: (err: HttpErrorResponse) => {
                console.log(err)
                this.errorModalWithMessage('app.invalid_reservation_data');
              }
            })
          },
          error: (err: HttpErrorResponse) => {
            console.log(err)
            this.errorModalWithMessage('app.out_of_service');
          }
        }
        )
      }})

    this.bottomBarService.bottomBarVisibilyChanged.subscribe(params => {
      this.bottomBarVisible = params
    })
  }

  ngOnDestroy(): void {
    this.loadBrandings$?.unsubscribe();
    this.loadSubscriberParameters$?.unsubscribe();
  }

  errorModalWithMessage(errorMessage: string) {
    this.spinnerService.setSpinnerVisibility(false);
    this.errorDialog.open(GenericMessageDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "85vw",
      data: { messageText: this.translate.instant(errorMessage), messageType: 'ERROR' }
    })
  }

  hasRegisteredGuest(resInfo: ReservationInfo): boolean {
    let hasReg: boolean = false;
    resInfo.Rooms.forEach(room => {
      room.Guests.forEach(guest => {
        if (guest.Status == GuestStayStatus.REGISTERED || GuestStayStatus.ROOM_AVAILABLE) {
          hasReg = true;
        }
      })
    })
    return hasReg;
  }

  bottomBarStyle(visible: boolean) {
    let styles = {};
    styles["visibility"] = this.bottomBarVisible ? 'visible' : 'hidden'
    styles["background-color"] = SessionStorage.BrandingData.PrimaryColor;
    return styles;
  }

  backStyle() {
    let styles = {};
    styles["background-color"] = SessionStorage.BrandingData.PrimaryColor;
    return styles
  }

  hasBrandingData(): boolean {
    return SessionStorage.BrandingData ? true : false
  }

  outletActivated(event:RouterOutlet) {
    this.routerOutletService.setRouterContentChanged(event)
  }
}
