
// Angular
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// 3rdparty
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
// Hostware

import SessionStorage from '@app/helpers/SessionStorage';

import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GenericMessageDialogComponent } from '@app/dialogs/generic-message-dialog/generic-message-dialog.component';
import { SignatureDialogComponent } from '@app/dialogs/signature-dialog/signature-dialog.component';
import { Country } from '@app/interfaces/Country';
import { GenericMessage } from '@app/interfaces/generic-message';
import { Subscription } from 'rxjs';
import { GuestDataFields } from '../../../../../froexishared/src/dto/GuestdataFields';
import { SubscriberParameter } from '../../../../../froexishared/src/dto/SubscriberParameter';
import { SubscriberParameterService } from '../../../../../froexishared/src/services/SubscriberParameter.service';
import { AdditionalData } from '@app/dto/AdditionalData';
import { AdditionalDataService } from '@app/services/additional-data.service';
import { RouterOutletService } from '@app/services/router-outlet.service';
import ChatVisibilityHelper from '@app/helpers/ChatvisibilityHelper';


@AutoUnsubscribe()
@Component({
  selector: 'app-additional-data',
  templateUrl: './additional-data.component.html',
  styleUrls: ['./additional-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdditionalDataComponent implements OnInit, OnDestroy {

  @ViewChild('accept') accept: MatCheckbox;
  countries: Country[] = [];
  csvLoaded: boolean;
  paramsLoaded: boolean;
  group: FormGroup;
  title: string;
  dataFields: GuestDataFields[] = [];
  conditionalFields: string[] = [
    'EMAIL',
    'PHONE',
    'PLATE_NUMBER'
  ];

  loadSubscriberParameter$: Subscription;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private dialog: MatDialog,
    private translate: TranslateService,
    private subscriberParameterService: SubscriberParameterService,
    private additionDataService: AdditionalDataService,
    private routerOutletService : RouterOutletService
  ) {

    this.group = this.fb.group({
      EMAIL: new FormControl('', [Validators.email,Validators.maxLength(60)]),
      PHONE: new FormControl('',[Validators.maxLength(30)]),
      PLATE_NUMBER: new FormControl('',Validators.maxLength(20)),
      Accept: new FormControl(false),
    })
    this.title =
      SessionStorage.ActiveGuestHeaderData().RoomTypeName + " - " +
      SessionStorage.ActiveGuestHeaderData().NumberOfGuests + " fő - " +
      SessionStorage.ActiveGuestHeaderData().GuestName
  }
  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.csvLoaded = true;
    this.loadSubscriberParameter$ = this.subscriberParameterService.loadByTypeCode(SessionStorage.GuestData.Subscriber, SessionStorage.GuestData.HotelId, 'DATA_FORM').subscribe(result => {
      const sub = result as SubscriberParameter
      this.dataFields = JSON.parse(sub.Param_Json_Value) as GuestDataFields[];
      // this.dataFields.find( item=> item.DataCode=="EMAIL").DataNature="M"
      this.conditionalFields.forEach(field => {
        if (this.fieldMustShow(field) && this.fieldRequired(field)) {
          if (field == "EMAIL") {
            this.group.controls[field].setValidators([Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]);
          } else {
            this.group.controls[field].setValidators(Validators.required);
          }
          this.group.controls[field].updateValueAndValidity();
        }
      })
      this.paramsLoaded = true;
    })
  }
  ngOnDestroy(): void {
    this.loadSubscriberParameter$.unsubscribe()
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  forwardClick() {

    let additionalData: AdditionalData = new AdditionalData();
    additionalData.GuestStayUuId = SessionStorage.SelectedGuestData.GuestStayUuId;
    additionalData.Hotel3pId = SessionStorage.ReservationInfo.Hotel3pId;
    additionalData.EmailAddress = this.fieldMustShow("EMAIL") ? this.group.controls["EMAIL"].value : "";
    additionalData.PhoneNumber = this.fieldMustShow("PHONE") ? this.group.controls["PHONE"].value : "";
    additionalData.PlateNumber = this.fieldMustShow("PLATE_NUMBER") ? this.group.controls["PLATE_NUMBER"].value : "";


    if (this.dataFields.find(item => item.DataCode == 'SIGNATURE').DataNature != 'N') {
      const dialogRef = this.dialog.open(SignatureDialogComponent,
        {
          disableClose: true,
          hasBackdrop: true,
        })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          additionalData.Signature = result;
          this.sendAdditionalData(additionalData);
        }
      })
    } else {
      additionalData.Signature = "";
      this.sendAdditionalData(additionalData);
      // this.router.navigateByUrl('/checkinstatus')
    }



  }


  sendAdditionalData(data: AdditionalData) {
    let message: string
    let messageData: GenericMessage
    let hasError: boolean = false;
    this.additionDataService.SendAdditionalData(data).subscribe({
      next: (result) => {
        messageData = { messageText: this.translate.instant('additional_data.details_save_ok'), messageType: 'INFORMATION' }
        this.showResultDialog(messageData,false)
      },
      error: (err) => {
        hasError = true;
        messageData = { messageText: this.translate.instant('common.network_error'), messageType: 'ERROR' }
        this.showResultDialog(messageData,true)
      }
      
    }
  )

  }

  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================

  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  fieldMustShow(data_code: string): boolean {
    const field = this.dataFields.find(item => item.DataCode == data_code);
    return field.DataNature == "N" ? false : true
  }
  fieldRequired(data_code: string): boolean {
    const field = this.dataFields.find(item => item.DataCode == data_code);
    return field.DataNature == "M" ? true : false
  }

  requiredChar(data_code: string): string {
    const field = this.dataFields.find(item => item.DataCode == data_code);
    return field.DataNature == "M" ? '*' : ''
  }

  formValid(): boolean {

    if (this.group.controls["Accept"].value == true && this.group.valid) {
      return true
    } else {
      return false;
    }
  }


  showResultDialog(messageData:  GenericMessage, hasError: boolean) {
    const okRef = this.dialog.open(GenericMessageDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      maxWidth: "auto",
      data: messageData
    })

    okRef.afterClosed().subscribe(data=> {
      if(!hasError) {
        this.router.navigateByUrl('/checkinstatus')
      }
      if( !this.fieldRequired("EMAIL") && !this.fieldRequired("PHONE") && !this.fieldRequired("PLATE_NUMBER") &&!this.fieldRequired("SIGNATURE") ) {
        this.router.navigateByUrl('/checkinstatus')
      }
    })
  }

  chatIconStyle() {
    let styles = {};
    styles = ChatVisibilityHelper.ChatVisibleStyle
    return  styles
  }

  //#endregion =================================================================================================================


}


/*
 EMAIL: new FormControl('', Validators.email),
      PHONE: new FormControl(''),
      PLATE_NUMBER: new FormControl(''),

*/